@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
}
